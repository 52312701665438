import React, { useContext, useState } from "react";

import i18n from "i18next";
import imgBanner from "../../images/homeLanding/imgBannerLanding.png";
import { LoadingContext } from "../context/LoadingContext";

const BannerHomeLanding = ({}) => {
    const {windowWidth} = useContext(LoadingContext)

    const mobileStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      };

    return (
       <>
        { windowWidth>1025 ?
        <div className="mobile:w-full desktop:pr-4 mobile:pr-4">

            <div className="banner-container">
                <img src={imgBanner} alt="Banner" className="banner-image" />
                <div className={windowWidth>=1700? "banner-content ml-12 mt-4" :"banner-content mt-4"}>
                    <h2 className="banner-title">{i18n.t("homeLanding.banner.title")}</h2>
                    <p className="banner-paragraph">
                        {i18n.t("homeLanding.banner.p1")}
                        <span>{i18n.t("homeLanding.banner.p2")}</span>
                        {i18n.t("homeLanding.banner.p3")}
                        <span>{i18n.t("homeLanding.banner.p4")}</span>
                        {i18n.t("homeLanding.banner.p5")}
                        <span>{i18n.t("homeLanding.banner.p6")}</span>
                        {i18n.t("homeLanding.banner.p7")}
                    </p>
                    <p className="banner-paragraph">
                        {i18n.t("homeLanding.banner.p8")}
                        <span>{i18n.t("homeLanding.banner.p9")}</span>
                        {i18n.t("homeLanding.banner.p10")}
                        <span>{i18n.t("homeLanding.banner.p11")}</span>
                        {i18n.t("homeLanding.banner.p12")}
                    </p>
                </div>
            </div>
        </div>

        :  
            <div className="mobile:w-full desktop:pr-4 mobile:pr-4">

                <div style={mobileStyle}>
                    <img src={imgBanner} alt="Banner" className="banner-image" />
                    <div className=" mt-4">
                        <h2 className="banner-title">{i18n.t("homeLanding.banner.title")}</h2>
                        <p className="banner-paragraphMobile">
                            {i18n.t("homeLanding.banner.p1")}
                            <span>{i18n.t("homeLanding.banner.p2")}</span>
                            {i18n.t("homeLanding.banner.p3")}
                            <span>{i18n.t("homeLanding.banner.p4")}</span>
                            {i18n.t("homeLanding.banner.p5")}
                            <span>{i18n.t("homeLanding.banner.p6")}</span>
                            {i18n.t("homeLanding.banner.p7")}
                        </p>
                        <p className="banner-paragraphMobile">
                            {i18n.t("homeLanding.banner.p8")}
                            <span>{i18n.t("homeLanding.banner.p9")}</span>
                            {i18n.t("homeLanding.banner.p10")}
                            <span>{i18n.t("homeLanding.banner.p11")}</span>
                            {i18n.t("homeLanding.banner.p12")}
                        </p>
                    </div>
                </div>
            </div>
        }
       </> 
    )
}


export default BannerHomeLanding;
