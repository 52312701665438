import React, { useContext } from 'react';
import i18n from 'i18next';

import microsoft from '../../images/homeLanding/LogoMicrosoftGold.png';
import cmmi from '../../images/homeLanding/cmmiSS.png';
import { LoadingContext } from '../context/LoadingContext';

const Experience = () => {

    const { windowWidth } = useContext(LoadingContext)
    const group4 = [microsoft, cmmi];

	return (
		<div className="customers__container mt-10 mb-20">
            
                <div className="mobile:w-full  mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 ">
                    <p className="titleCompanies">{i18n.t("homeLanding.ourExperience")}</p>              
                </div>

                <div className="mobile:w-full landscape:w-full tablet:w-full desktop:w-full pl-4">
                    {windowWidth>1025 ? 
                        <div className="desktop:flex contentCompaniesLanding gap-5">
                        {group4.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt={`Image ${index}`} />
                            </div>
                        ))}
                        </div>
                    
                    :
                        <div className="desktop:flex flex flex-wrap contentCompaniesLandingMobile gap-5">
                        {group4.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt={`Image ${index}`} />
                            </div>
                        ))}
                        </div>

                    }
                    
                </div>
		</div>
	);
};

export default Experience;