import React from "react";
import i18n from "i18next"

import BannerSEO from "./../images/home/Home1IN.png"

//images

import iconPurple from "./../images/home/iconPurple.png";
import iconGreen from "./../images/home/iconGreen.png";
import iconBlue from "./../images/home/iconBlue.png";


// components

import PrivacyPolicy from "../components/shared/privacyPolicy/PrivacyPolicy";

import { withNamespaces } from "react-i18next"

// Layouut
import Layout from "../components/layout"
import SEO from "../components/seo" 
import BannerHomeLanding from "../components/home/BannerHomeLanding";
import Experience from "../components/home/Experience";

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openPrivacyPolicy: false,
    }

    this.redirectTalent = this.redirectTalent.bind(this)
    this.redirectInnovation = this.redirectInnovation.bind(this)
    this.redirectDataAnalytics = this.redirectDataAnalytics.bind(this)
    this.redirectDev = this.redirectDev.bind(this)
    this.redirectAgile = this.redirectAgile.bind(this)
    this.redirectJoin = this.redirectJoin.bind(this)
    this.redirectPost = this.redirectPost.bind(this)
    this.setPrivacy = this.setPrivacy.bind(this)
    this.myRef = React.createRef();
    this.executeScroll = this.executeScroll.bind(this)
  }

  executeScroll() {
    this.myRef.current.scrollIntoView();
  }

  setPrivacy() {
    this.setState({ openPrivacyPolicy: false })
  }

  onEnterOurServicesViewPort() {
    document
      .getElementById("ourServices")
      .classList.add("main-title--animation")
  }

  onExitOurServicesViewPort() {
    document
      .getElementById("ourServices")
      .classList.remove("main-title--animation")
  }

  onEnterOurServicesBackgroundViewPort() {
    document
      .getElementById("ourServicesBackground")
      .classList.add("background-our-services--animation")
  }

  onEnterJoinOurTeamViewPort() {
    document
      .getElementById("joinOurTeam")
      .classList.add("main-title--animation")
  }

  onExitJoinOurTeamViewPort() {
    document
      .getElementById("joinOurTeam")
      .classList.remove("main-title--animation")
  }

  onEnterTransformViewPort() {
    document.getElementById("transform").classList.add("main-title--animation")
  }

  onExitTransformViewPort() {
    document
      .getElementById("transform")
      .classList.remove("main-title--animation")
  }

  redirectTalent() {
    this.props.history.push("/service-talent")
  }

  redirectInnovation() {
    this.props.history.push("/service-innovation")
  }

  redirectDataAnalytics() {
    this.props.history.push("/service-data-analytics")
  }

  redirectDev() {
    this.props.history.push("/service-dev-cloud")
  }

  redirectAgile() {
    this.props.history.push("/transformation")
  }

  redirectJoin() {
    this.props.history.push("/join-our-team")
  }

  redirectPost() {
    this.props.history.push("/blog")
  }

  render() {
    

    
    const whyusarr = [
      {
          image: iconGreen,
          texto: "Fábrica de software",
          textEnglish: "Software Factory",
      },
      {
          image: iconPurple,
          texto: "Equipos ágiles",
          textEnglish: "Agile Teams",
      },
      {
          image: iconBlue,
          texto: "Aumento del personal",
          textEnglish: "Staff Augmentation",
      },
    ];

    return (
      <Layout>
        {this.state.openPrivacyPolicy ? (
          <PrivacyPolicy setOpenPrivacyPolicy={this.setPrivacy} />
        ) : null}
        <SEO
        
          title={i18n.t("titles.titleseo")}
          description={i18n.t("titles.descriptionseo")}
          image={BannerSEO}
        />
        <BannerHomeLanding />

        <Experience />  
      </Layout>
    )
  }
}

export default withNamespaces()(Home)
